import React, {Component} from 'react';
import ReactWebMediaPlayer from 'react-web-media-player';
import './App.css';

// const {REACT_APP_RESOLVER_URL} = process.env;
const REACT_APP_RESOLVER_URL = "https://sandbox.qrcode.link/m/"

function App() {

    const [loading, setLoading] = React.useState(false);
    const [errored, setErrored] = React.useState(false);
    const [content, setContent] = React.useState({});

    React.useEffect(() => {
        setLoading(true);

        const codeID = window.location.href.split("/")[window.location.href.split("/").length - 1];

        fetch(REACT_APP_RESOLVER_URL + codeID).then(res => res.json()).then((result) => {

            setContent(result);
        }, (error) => {
            setErrored(true);
        });

    }, []);

    return (
        <div className="App">
            <div className="Title">
                {content.title}
            </div>
            <div className="Content">
                <ReactWebMediaPlayer
                    title={content.title}
                    video={content.url}
                    logo={{img: 'https://unitag-public-assets.s3-eu-west-1.amazonaws.com/img/Unitag-styled-logo.png', href: 'https://unitag.io'}}
                    width={"100%"}
                />
            </div>
            <div className="Footer">
                Powered by Unitag
            </div>

        </div>
    );
}

export default App;
